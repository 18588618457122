<template>
	<div ref="editor" class="code-editor"></div>
</template>

<script>
import { edit, EditSession } from "ace-builds";
import { Mode } from "ace-builds/src-noconflict/mode-javascript.js";

EditSession.prototype.$useWorker = false;

export default {
	name: "CodeEditor",
	props: {
		value: String,
	},
	watch: {
		value() {
			if ( this.value !== this.latest ) {
				this.latest = this.value;
				this.editor.setValue( this.value );
			}
		}
	},
	methods: {
		focus() {
			this.editor.focus();
		},
	},
	mounted() {
		this.editor = edit( this.$refs.editor );
		this.editor.session.setMode( new Mode() );
		this.editor.resize();

		this.latest = this.value;
		this.editor.setValue( this.value );
		this.editor.gotoLine( 1 );

		this.editor.session.on( "change", () => {
			const value = this.editor.getValue();

			if ( value !== this.latest ) {
				this.latest = value;
				this.$emit( "input", value );
			}
		} );
	},
	beforeDestroy() {
		if ( this.editor ) {
			this.editor.destroy();
			this.editor.container.remove();
		}
	},
};
</script>
